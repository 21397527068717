import * as React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { Maybe } from '../../types/graphql-types';
import Video from './video';
import { Header3 } from './headers';

type Props = {
  document?: {
    raw?: Maybe<string>;
    references?: Maybe<
      Maybe<ContentfulRichTextGatsbyReference & Record<string, unknown>>[]
    >;
  } | null;
};

const ContentfulRichText: React.FC<Props> = ({ document }: Props) => {
  if (!document || !document.raw) {
    return null;
  }
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: function Image(node): React.ReactNode {
        if (!node.data.target.gatsbyImageData) return undefined;
        return (
          <GatsbyImage
            alt={node.data.target.description || node.data.target.title || ''}
            image={node.data.target.gatsbyImageData}
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: function Entry(node): React.ReactNode {
        // eslint-disable-next-line no-underscore-dangle
        const typename = node?.data?.target?.__typename;
        if (typename === 'ContentfulVideo') {
          if (node.data.target.embedUrl && node.data.target.thumbnail) {
            return (
              <Video
                videoUrl={node.data.target.embedUrl}
                photo={node.data.target.thumbnail}
              />
            );
          }
        } else if (typename === 'ContentfulTable') {
          const tableData = node?.data?.target?.data?.tableData || [];
          if (tableData.length < 2) {
            return null;
          }
          const name = node?.data?.target?.name || 'untitled';
          return (
            <table>
              <thead>
                <tr>
                  {tableData[0].map((cell: string) => (
                    <td key={`${name}-header-${cell}`}>{cell}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.slice(1).map((row: string[]) => (
                  <tr key={`${name}-row-${row[0]}`}>
                    {row.map((cell: string) => (
                      <td key={`${name}-header-${cell}`}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }
        return null;
      },
      [BLOCKS.PARAGRAPH]: function Paragraph(node, children): React.ReactNode {
        return <p className="rich-paragraph my-4 mx-5 md:mx-0">{children}</p>;
      },
      [BLOCKS.HEADING_3]: function RichHeader3(
        node,
        children
      ): React.ReactNode {
        return (
          <Header3
            className="rich-header-3 my-4 mx-5 md:mx-0 font-bold"
            tag="h3"
          >
            {children}
          </Header3>
        );
      },
      [BLOCKS.HEADING_4]: function RichHeader3(
        node,
        children
      ): React.ReactNode {
        return (
          <Header3
            className="rich-header-3 my-4 mx-5 md:mx-0 font-bold"
            tag="h4"
          >
            {children}
          </Header3>
        );
      },
    },
  };
  const documentToRender = { ...document };
  if (!document.references) {
    documentToRender.references = [];
  }
  return (
    <div>
      {renderRichText(
        documentToRender as RenderRichTextData<ContentfulRichTextGatsbyReference>,
        options
      )}
    </div>
  );
};

ContentfulRichText.defaultProps = {
  document: undefined,
};

export default ContentfulRichText;
