import React, { useState } from 'react';

import { isSSR } from '../../utils';
import Placeholder from './placeholder';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { ContentfulAsset, Maybe } from '../../../types/graphql-types';

const Player = React.lazy(() => import('./player'));

type VideoProps = {
  videoUrl: string;
  photo?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
  title?: string;
};

const Video: React.FC<VideoProps> = ({
  videoUrl,
  photo,
  title,
}: VideoProps) => {
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  return (
    <div className="video bg-black">
      {(!showPlayer || isSSR) && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => setShowPlayer(true)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setShowPlayer(true);
            }
          }}
        >
          <Placeholder photo={photo} />
        </div>
      )}
      {showPlayer && !isSSR && (
        <React.Suspense fallback={<Placeholder photo={photo} />}>
          <Player videoUrl={videoUrl} title={title} />
        </React.Suspense>
      )}
    </div>
  );
};

Video.defaultProps = {
  photo: undefined,
  title: undefined,
};

export default Video;
