import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { ContentfulAsset, Maybe } from '../../../types/graphql-types';

type PlaceholderProps = {
  photo?: Maybe<Pick<ContentfulAsset, 'gatsbyImageData'>>;
};

const Placeholder: React.FC<PlaceholderProps> = ({
  photo,
}: PlaceholderProps) => (
  <div
    className="video-placeholder w-full bg-black relative overflow-hidden cursor-pointer"
    style={{ paddingBottom: '56.25%' }}
  >
    {photo && (
      <div className="absolute w-full top-0 left-0">
        <GatsbyImage alt="TODO: add alt tag" image={photo?.gatsbyImageData} />
      </div>
    )}
    <div
      className="absolute transform -translate-x-1/2 -translate-y-1/2 text-white text-6xl"
      style={{
        left: '50%',
        top: '50%',
        textShadow:
          '0px 0px 2px rgb(0 0 0 / 20%), 0px 0px 2px rgb(1 0 5 / 10%)',
      }}
    >
      &#x25BA;
    </div>
  </div>
);

Placeholder.defaultProps = {
  photo: undefined,
};

export default Placeholder;
